.proj_containar_section {
  width: 90%;
  padding: 20px 30px;
}

.proj_left span{
  font-size: 30px;
  color: red;
}

.proj_containar_section,
.proj_left,
.proj_right {
  display: flex;
  align-content: center;
  justify-content: center;
}
.proj_left,
.proj_right {
  flex-wrap: wrap;
  width: 50%;
  flex-grow: 1;
}
.proj_right figcaption{
  text-align: center;
  font-style: italic;
}
.proj_left {
  text-align: left;
  gap: 12px;
  flex-basis: 600px;
}
.proj_left h3 {
  font-size: 36px;
  flex-wrap: wrap;
  display: flex;
}
.proj_left div{
  margin-top: 50px;
}
.proj_left p {
  line-height: 35px;
  font-size: 18px;
  width: 90%;
}
.proj_left h3,
.proj_left p,
.bot_btn {
  width: 90%;
}
.proj_right img {
  width: 450px;
  height: auto;
  clip-path: circle();
}

@media screen and (max-width: 920px) {
  .proj_containar_section {
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .proj_left,
  .proj_right {
    width: 90%;
  }
  .bot_btn {
    margin: 0;
  }
  .proj_left h3,
  .proj_left p,
  .bot_btn {
    width: 100%;
  }
  .proj_left p {
    text-align: left;
  }
}
@media screen and (max-width: 720px) {
  .proj_containar_section {
    flex-direction: column;
  }
  .proj_right img {
    margin-left: 6%;
    width: 90%;
    height: auto;
    border-radius: 100%;
    clip-path: circle();
  }
}

@media screen and (max-width: 420px) {
  .proj_left h3 {
    font-size: 30px;
    text-align: left;
    padding-top: 18px;
  }
  .proj_containar_section {
    padding: 20px 18px;
  }
}
