.container {
  margin: 1rem;
  text-align: center;
}
.nav-container,
.logo {
  display: flex;
  align-items: center;
  align-content: center;
}
.nav-container {
  flex-direction: row;
  justify-content: space-between;
  z-index: 100;
  top: 0;
  position: sticky;
  width: 100%;
  color: #fff;
  height: 72px;
  backdrop-filter: blur(6px);
}
.logo {
  justify-content: space-evenly;

  padding: 0px 0 0 21px;
  padding-bottom: 10px !important;
}
.logo a {
  text-decoration: none;
  font-size: 24px;
  height: 50px;
  color: black;
  font-weight: 600;
}
.logo a:hover {
  color: #ff8c09;
}

.logo img {
  width: 110px;
}
.links {
  transform-origin: 0% 0%;
  position: relative;
  z-index: 50;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}
.MenuItems,
.links,
.active_links {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}
.MenuItems {
  text-align: center;
  font-size: 18px;
  line-height: 39px;
  margin-right: 12px;
}
.MenuItems a {
  text-decoration: none;
  font-weight: 600;
  font-size: 16px;
  color: #fff;
  line-height: 24px;
  text-align: center;
}
.MenuItems a:hover {
  color: #ff32d9 !important;
  background: #70707042;
  border-radius: 9px;
}
.active_navbtn {
  color: #3299ff !important;
  text-shadow: 0 0 3px #ddfffd34;
  font-size: 18px !important;
  background: #ffffff1c;
  border-radius: 9px;
}
.MenuItems a:active {
  transform: scale(0.9);
}
.logo h4 {
  color: #000;
}
.active_links {
  position: absolute;
  height: 100vh;
  width: 100%;
  top: 72px;
  flex-direction: column;
  z-index: 71;
  background: #000;
  justify-content: flex-start;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}
.toggle_menu_icons {
  display: none;
}
.nav_a,
.nav_c {
  padding: 0 10px;
}
.MenuItems span {
  display: none;
}
@media screen and (min-width: 1024px) {
  .nav-container {
    height: 80px;
    background: #4544442b;
  }

  .links a {
    padding: 12px;
  }
}
@media screen and (max-width: 1024px) {
  .MenuItems {
    flex-direction: column;
    gap: 21px;
    font-size: 21px;
    height: 512px;
    width: 90%;
    transition: transform 0.4s ease-in-out;
  }
  .MenuItems a {
    color: #0c3581;
    width: 100%;
    height: 50px;
    align-items: center;
    justify-content: flex-start;
    display: flex;
    outline: none;
    border: none;
    border-radius: 3px;
  }
  .toggle_menu_icons {
    display: flex;
    height: 50px;
    margin-right: 21px;
    align-items: center;
    justify-content: center;
    align-content: center;
  }

  .links {
    width: 100%;
    transform: translate(-100%, 0);
    height: 100vh;
    background-color: #fff;
    justify-content: flex-start;
    flex-direction: column;
    display: flex;
    top: 72px;
    position: absolute;
  }
  .active_links {
    transition: transform 0.4s ease-in-out;
  }
  .btn_active {
    background: #00a5ee;
    border-radius: 3px;
    box-shadow: 0 0 2px #00a5ee;
    color: #fff;
  }
  .btn {
    color: #fff;
  }
}

@media screen and (max-width: 820px) {
  .your_links {
    display: none;
    transition: all 1.5s linear;
    transform: translateX(-333px);
    position: relative;
    top: 190px;
  }
  .MenuItems a {
    gap: 10px;
    font-size: 18px;
  }
  .MenuItems span {
    display: flex;
  }
  .active_navbtn {
    color: #c200d0 !important;
    transform: scale(0.99);
  }
  .MenuItems a:hover {
    border-radius: 3px;
    color: #ffffff;
    box-shadow: 0 0 1px #fff;
    outline: none;
    background: transparent;
  }
  .nav-container {
    background: #8f8f8f1c;
  }
}
@media screen and (max-width: 580px) {
  .MenuItems a {
    color: #0c3581;
  }
  .logo a {
    font-size: 21px;
  }
}
@media screen and (max-width: 480px) {
  #navbar li {
    width: 100%;
    margin: 10px;
  }
  .nav {
    background-color: #fff;
    height: 82px;
  }
  #navbar {
    flex-direction: column;
  }

  .your_links {
    display: none;
    transition: all 1.5s linear;
    transform: translateX(-333px);
    position: relative;
    top: 190px;
  }

  .hamburger-menu {
    display: flex;
  }

  .hamburger-menu a {
    font-size: 18px;
    text-align: center;
    width: 20px;
    justify-content: center;
  }
  .mobile_menu_links,
  .hamburger-menu a,
  .nav {
    display: flex;
    align-items: center;
    align-content: center;
  }
  .mobile_menu_links {
    background-color: #fff;
    height: 20rem;
    padding-left: 3rem;
    transition: all 1.5s linear;
    position: relative;
    top: 190px;
    z-index: 99;
    transform: translateX(-162px);
    animation: fade-in 1s forwards;
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}