.brands_me{ 
    display: flex;
    height: 40vh;
    width: 100vw; 
    align-items: center;
    justify-content: center;
    background-color: #fff;
    }
.brands_me img{
    height:auto;
    width:60%;
}

@media screen and (max-width : 810px) {
    .brands_me img{
        height:130px;
        width: 100%;
    }
    
}