

.new-mid-text {
  display: flex;
  gap: 9px;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: red;
  color: white;
}


.profile-details-name p {
  font-size: 60px;
  line-height: 90px;
  color: transparent;
  font-weight: 500;
  text-transform: uppercase;
  color: #ff5823;
  -webkit-text-stroke: 1px #af8686;
  text-shadow: 0px 0px 50px #3aaef77a;
}


@media only screen and (max-width: 568px) {

}

.red-border {
  border: 1px solid var(--error-message) !important;
}

.fade-in {
  opacity: 0;
  transform: translateY(80px);
  transition: all 0.8s ease;
}
.profile-container,
.profile-parent {
  display: flex;
  align-items: center;
  text-align: center;
}
.profile-container {
  margin-top: 0%;
  padding-top: 0%;
  justify-content: flex-end;
  background-image: url("../Image/front.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-color: rgb(0, 0, 0);
  flex-direction: row;
  position: sticky;
}
.profile-details{
  margin-left: 50% !important;
}
.profile-parent {
  width: 90%;
  text-align: left;
  justify-content: center;
}
.profile-details-name {
  font-size: 70px;
  line-height: 40px;
}
.profile-details-role {
  display: flex;
  flex-direction: column;
  margin: 15px 0 18px 0;
}
.profile-details-role h1 {
  font-size: 35px;
  line-height: 32px;
  margin: 0 0 6px 0;
  text-transform: uppercase;
  text-shadow: 0px 0px 50px rgba(0, 133, 234, 0.5);
}
.highlighted-text {
  margin-left: 0px in !important
  }
.colz {
  padding: 12px 0px;
}
.primary-text {
  color: #ff5823;
  font-size: 24px;
  width: 90%;
}

.highlighted-text {
  font-weight: 200px;
  font-size: 60px !important;
  text-shadow: 0 0 9px #1d1122;
  color: #ffffff;
  margin-right: 0px;
  margin-left: 0px;
  font-family: 'Times New Roman', Times, serif;
 
  font-weight: 0;
}
.profile-role-tagline {
  text-align: center;
  font-size: 26px;
  line-height: 2px;
  margin: 1px 0 0 0;
  color: rgb(127, 141, 170);
}

.profile-picture {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 3px 0px 0px #ffffff;
  background-image: url('../Image/sky.svg');
  height: 400px;
  width: 400px;
  clip-path: circle();
}

.profile-picture-background:hover {
  animation: bounce_one 1s infinite;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}
@keyframes bounce_one {
  0% {
    transform: translateY(0);
  }
  40%,
  60% {
    transform: translateY(-9px);
  }
  70% {
    transform: translateY(-8px);
  }
}
.profile-picture-background {
  height: 90%;
  width: 90%;
  background-image: url(../Image/ecomm.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  clip-path: circle(50%);
}
/* .profile-picture-background:hover {
  transform: scale(1.07);
  transition: 1s ease-out;
} */
.colz .colz-icon i {
  color: #fff;
  padding: 8px;
  padding-left: 0px;
  font-size: 30px;
}

.colz .colz-icon i:hover {
  transform: scale(1.2);
}

@media screen and (min-width: 1024px) {
  .profile-details {
    width: 60%;
  }
  .profile-parent {
    height: 100vh;
    padding-bottom: 100px;
  }
  .profile-picture {
    margin: 0 0px 0 80px;
  }
  .profile-details-name {
    font-size: 20px;
  }
  .profile-details-role .primary-text {
    font-size: 12px;
  }

}
@media screen and (max-width: 1024px) {
  .profile-details{
    margin-left: 0% !important;
  }
  .highlighted-text{
    line-height: 60px;
  }

  .profile-container{
    background-image: none;
    background-color: #000;
  }
  .profile-details-name p {
    font-size: 45px;
    line-height: 54px;
  }
  .profile-parent {
    height: 100vh;
  }
}
@media screen and(max-width:910px) {
  .profile-picture {
    height: 320px;
    width: 320px;
    margin: 0 0 0 4px;
  }
}
@media screen and (max-width: 810px) {
  .profile-container {
    width: 100%;
    background-color: #000;
    padding: 18px 20px 21px 0;
    margin: 0%;
    background-size: cover;
    clip-path: polygon(100% 0, 100% 96%, 54% 100%, 0 94%, 0 0);
  }
  .profile-picture {
    margin-bottom: 30px;
  }
  .profile-details-role h1 {
    height: 100px;
    text-align: center;
    display: none !important;
  }
  .profile-details-name p {
    font-size: 30px;
    line-height: 36px;
    text-decoration-color: blueviolet;
  }
  .profile-parent {
    flex-direction: column-reverse;
    margin: 40px 0 0 0;
    width: 90%;
    height: 100% !important;
    justify-content: center;
    text-align: center;
  }
  .profile-options {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
  }
  .profile-deails {
    display: flex;
    margin-top: 15px;
    margin: 25px 0 0;
    justify-content: center;
    text-align: center;
  }
  .primary-text {
    line-height: 24px;
  }

  .colz .colz-icon i {
    color: #ffffff;
    text-shadow: 0px 0px 1px #ffffff;
  }
  .profile-options .highlighted-btn {
    margin: 0 !important;
  }
  .profile-options button {
    font-size: 14px !important;
  }
  .profile-options,   
  .profile-details-role {
    text-align: center;
  }
  .profile-options {
    padding-bottom: 31px;
  }
}
@media screen and (max-width: 480px) {
  .primary-text {
    width: 100%;
  }
  .profile-parent {
    margin: 0 !important;
  }
  .profile-deails {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .profile-options {
    z-index: 10;
    position: relative;
  }
}
@media screen and (max-width: 420px) {
  .profile-picture {
    height: 342px;
    width: 342px;
  }
  .profile-picture-background {
    height: 85%;
    width: 85%;
  }
  .profile-details-name {
    margin: 6px 0 0 0;
  }
  .profile-details-role h1 {
    margin: 0 0 6px 0;
    text-align: center;
    height: 90px;
  }
}
@media screen and (max-width: 375px) {
  .profile-picture {
    height: 275px;
    width: 278px;
  }
  .profile-deails {
    width: 90%;
  }
}

@media screen and (max-width: 330px) {
  .profile-options {
    flex-direction: column;
    gap: 20px;
  }
}
