.Contact_us{
    display: flex;
    flex-direction: row;
    height: 70vh;
    width: 100vw;
    align-items: center;
    justify-content: center;
    background-color: white;
}

.Contact_us img{
    height:auto;
    width:30%;
}

@media screen and (max-width : 810px) {
    .Contact_us img{
        height:auto;    
        width: 90%;
    }
    
}