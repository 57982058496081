.about_me {
  width: 100%;
  background: #f8f8ff;
}

.animationh{
  padding-left:17%;
  padding-right: 18%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2.4rem;
  margin-bottom:2.4rem;
  white-space: nowrap;
  font-size: 50px !important;
}
.animationh h2{
  margin-top: 3%;
}
hr{
  margin-top: 20px;
  border-bottom: 1px solid black;
  width: 80%;
}
.about_me,
.container_01,
.my_img,
.my_info,
.personal_info {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}
.pass{
  padding-left:18%;
  padding-right:18%;

  font-size: 23px;

}
.container_01 {
  width: 100%;
  padding: 30px 0px;
}
.ii-abt{
  padding-left: 18px;
}
.my_img {
  width: 45%;
  margin: 67px 0 35px 0;
}
.DisQ{
  background-image: url("../Image/starry2.png");
}
#img {
  display: flex;
}
.imgL img{
  clip-path: circle();
}
h2 {
  margin: 10px;
  font-size: 60px;
}
h2 span {
  color: #ff5823;
}
.my_info {
  width: 80%;
  color: #000;
  flex-direction: row;
  flex-wrap: wrap;
  line-height: 35px;
  font-family: system-ui;
}
.my_info p {
  width: 90%;
  margin: 3px;
  font-size: 18px;
  color: #000;
  padding: 2px 15px 20px 15px;
}
h4 {
  font-size: 17px;
  font-weight: 500;
}
.Distribution{
  background-image:url("../Image/starry2.png");
  background-attachment: fixed;
}
.ecommHead span{
  color: white;
  font-size: larger;
}
.DisH{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

h4 span {
  font-size: 18px;
  font-weight: 700;
  color: #ff5823;
}
.my_img p{
  font-size: larger !important;
}
.info{
  width: 100%;
}

.personal_info {
  width: 90%;
  justify-content: space-around;
  flex-direction: row;
}
.ecommQ{
  
  background-image: url("../Image/starry2.png");
  background-attachment: fixed;
  margin-left: 10%;
  margin-right: 10%;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 5rem;  
}
.DisQ{
  margin-left: 10%;
  margin-right: 10%;
}
.imgL img{
  margin: 10% 30% 10% 0%;
  height: 400px;
  width: auto;
}
.Ecommerce h2{
  color: #ff5823;
  font-size: 32px;
  text-align: center;
  margin-top: 15px;
}
hr{
  margin-bottom: 15px;
}
.ecommHead{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap:2px;
}
.white{
  padding-top: 20px;
  background-color: white;
}
.Ecommerce p{
  color: white;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: larger;
}
.Distribution{
  background-color: #000;
  
}
.Distribution h2{
  color: #ff5823;
  text-align: center;
  font-size: 32px;
  margin-top: 10px;
}
.Distribution p{
  color: white;
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 25px ;
  text-align: center;

  font-style: italic;
}
.Distribution{
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: row-reverse;
  gap:3rem;
  
}
.imgD{
  background-color: #000;
  margin: 5% 10% 5% 0%;
}
.imgD img{
  clip-path: circle();
  height: 350px;
  width: auto;
}
.DisH span{
  color: white;
  font-size: larger;
}

.Distribution ul{
  color: white;
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  padding-left: 30%;
  font-size: larger;
  line-height: 50px;
}

/* For Mobile */
@media screen and (max-width: 810px) {
    .imgL,
    .imgD{
      display: none;
    }
    .animationh h2{
      color: #000 !important;
      padding-left: 0%;
      margin-left: 0%;
      margin-right: 20px !important;
      text-align: left;
      text-shadow: #000;
    }
    .pass{
      padding-left: 10% !important;
      padding-bottom: 5% !important;
    }
    .pass p{
      color: #000 !important;
      padding-left: 0%;
      padding-right: 0%;
      margin-left: 0%;

    }
    .about_me{
      margin-top: 160px;
    } 
    .Ecommerce p{
      padding-left: 30px !important;
      padding-right: 30px !important;
      line-height: 40px;
      font-size: 20px;
    }
    .Distribution p{
      padding-left: 40px !important;
      padding-right: 40px !important;
      line-height: 40px;
      font-size: 21px;
    }
    .Distribution ul{
      padding-left: 60px;
    }
    .animationh.line{
      background-color: white;
    }
    .container_01{
      width: 100% !important;
    }
    .ecommQ{
      display: flex;
      flex-direction: column;
      justify-content: center !important;
      align-items: center;
    }
    
    .imgL img{
      margin-left: 15%;
      margin-bottom: 15%;
      width: 60%;
      height: auto;
    }
    .imgL{
      align-content: center !important;
      background-color: black;
    }
    .Distribution{
      display:flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap:3rem;
      
    }
    .imgD{
      align-content: center !important;
      background-color: #000;
    }
    .imgD img{
      margin-left: 25%;
      margin-bottom: 15%;
      width: 60%;
      height: auto;
    }
    .DisH h2{
      margin: 10px !important;
    }
    .DisH{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
    }
}





@media screen and (min-width: 1024px) {
  .my_info {
    gap: 27px;
  }
  .my_info p {
    font-size: 23px;
  }
  .my_img {
    width: 45%;
  }
  .my_img img {
    width: 567px;
  }
}
@media screen and (max-width: 1024px) {
  .my_img img {
    width: 523px;
  }
  .my_info {
    gap: 27px;
  }
}
@media screen and (max-width: 900px) {
  .my_img img {
    width: 500px;
  }


}
@media screen and (max-width: 720px) {
  .container_01 {
    flex-direction: column;
  }
  .personal_info {
    flex-direction: column;
    margin: 1px 0px 15px 0px;
  }

}
@media screen and (max-width: 580px) {
  .my_img {
    width: 90%;
    margin: 35px 0 5px 0 !important;
  }

}
@media screen and (max-width: 480px) {
  .container_01 {
    width: 100%;
    flex-direction: column;
    position: relative;
    color: #fff;
    padding: 15px 0 25px 0;
  }
  .my_img img {
    width: 100%;
  }
  .my_info {
    width: 90%;
  }
  .my_info h2 {
    margin: 10px;
  }
  .my_info p {
    width: 100%;
  }
  .personal_info {
    width: 100%;
  }

}
