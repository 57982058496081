.container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    background-color: white;
    margin: 10% 0% 0% 0%;
    padding: 0px 0px 0px 0px;
}
.card{
    box-shadow: 10px 10px lightgrey;
    margin: 5%;
    border: solid rgb(0, 0, 0);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px; 
}
.passage span{
    color: white !important;
}
.container span{
    color:  #ff5623;
}
.container hr{
    margin-top: 0%;
    margin-bottom: 0%;
    width: 100%;
}

.h{
    background-color: rgb(218, 218, 218) ;
    padding: 0% !important;
    margin: 0% !important;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.h h3{
    text-align: center;
    font-size: 30px;
    padding:10px;
    /*color: #ff5823;*/
}
.card{
    flex-basis: 300px;
    flex-grow: 0;
    flex-shrink: 0;
    
}
.card p{
    font-size: larger;
    line-height: 40px;
    padding:3% 3% 3% 3%;
    text-align: left;
    overflow-wrap: normal;
}
.card p{
    color: rgb(0, 0, 0);
}
