@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,500;1,800&display=swap');

* {
  margin: 0;
  padding: 0;
  -ms-overflow-style: none;
  box-sizing: border-box;
  scroll-behavior: smooth;
  /* font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif; */
  font-family: 'Poppins', sans-serif;
  transition: all 0.25s linear 0s;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
::-webkit-scrollbar {
  display: none;
}

body {
  margin: 0px;
  background-color: #000;
}
