.container1{
    margin-top: 0% !important;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    background-color: white;
    margin: 10% 0% 0% 0%;
    padding: 0px 0px 0px 0px;
}
.core{
    background-color: black;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
}
.core h2{
    flex-basis: 900px;
    flex-grow: 0%;
    flex-shrink: 0%;
    color: #ff5623;
    font-size: 45px;
    margin-top: 0%;
    margin-bottom: 0%;
}
.card1 {
    box-shadow: 12px 12px #ff56235d;
    margin: 5%;
    border: solid #ff5623 3px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px; 
}
.container1 span{
    color:#ff5823;
}
.container1 hr{
    margin-top: 0%;
    margin-bottom: 0%;
    width: 100%;
}

.h1{
    background-color: rgb(0, 0, 0) ;
    padding: 0% !important;
    margin: 0% !important;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.h1 h3{
    text-align: center;
    font-size: 30px;
    padding:10px;
    color: #ff5823;
}
.card1 {
    flex-basis: 400px;
    flex-grow: 0;
    flex-shrink: 0;
    
}
.card1  p{
    font-size: larger;
    line-height: 40px;
    padding:3% 3% 3% 3%;
    text-align: left;
    overflow-wrap: normal;
}
.card1  p{
    color: white;
}
.passage1{
    background-image: url("../Image/starry2.png");
}
.passage1 img{
    width:400px;
    height: auto;
}
@media screen and (max-width:600px) {
    .card1 {
        flex-basis: 300px;
        flex-grow: 0;
        flex-shrink: 0;
        
    }
    .passage1 img{
        width:300px;
        height: auto;
    }
    .core h2{
        margin: 5% 0% 5% 0%;
        font-size: 25px;
    }
    
}